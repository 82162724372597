import React, { useState } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogCardPage1 from '../components/BlogContent/BlogCardPage1'
import BlogCardPage2 from '../components/BlogContent/BlogCardPage2'
import shape1 from '../assets/images/shape/shape1.svg'
import shape2 from '../assets/images/shape/shape2.png'
import shape3 from '../assets/images/shape/shape3.png'
import ProjectStartArea from '../components/Index/ProjectStartArea';

const Blog = () => {
    const [page, setPage] = useState(1);

    return (
        <Layout>
            <Navbar />
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Hot off the AR press</h2>
                        <p>Don’t miss the latest news around augmented reality and the whole AR industry.</p>
                    </div>
                </div>

                <div className="shape-img1">
                    <img src={shape1} alt="banner" />
                </div>
                <div className="shape-img2">
                    <img src={shape2} alt="banner" />
                </div>
                <div className="shape-img3">
                    <img src={shape3} alt="banner" />
                </div>
            </div>
            {
                page === 1 ?
                    <BlogCardPage1 />
                    :
                    <BlogCardPage2 />

            }
            <div className="col-lg-12 col-md-12" style={{ paddingBottom: '100px' }}>
                {/* Pagination */}

                <div className="pagination-area text-center">

                    {/* <button className="prev page-numbers" onClick={() => setPage(2)}>
                                <i className='bx bx-chevrons-left'></i>
                            </button> */}

                    <button onClick={() => setPage(1)} className="prev page-numbers" style={{ border: 'none' }}>
                        <i className='bx bx-chevrons-left'></i>
                    </button>


                    {
                        page === 1 ?
                            (
                                <div style={{ display: 'inline' }}>
                                    <span className="page-numbers current" aria-current="page">1</span>
                                    <button onClick={() => setPage(2)} className="page-numbers" style={{ border: 'none' }}>2</button>
                                </div>
                            ) :
                            (
                                <div style={{ display: 'inline' }}>
                                    <button onClick={() => setPage(1)} className="page-numbers" style={{ border: 'none' }}>1</button>
                                    <span className="page-numbers current" aria-current="page">2</span>
                                </div>
                            )

                    }

                    <button onClick={() => setPage(2)} className="next page-numbers" style={{ border: 'none' }}>
                        <i className='bx bx-chevrons-right'></i>
                    </button>
                </div>
            </div>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Blog;