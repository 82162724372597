import React from 'react'
import BlogCard from './BlogCard'
import img1 from '../../assets/images/blog/visionar_augmented_reality_top-10-ar-sdk_792-352-1.jpg'
import img2 from '../../assets/images/blog/visionar_augmented_reality_developing-native-hybride-or-web-app_792-352-1.jpg'
import img3 from '../../assets/images/blog/visionar_augmented_reality_augmented-reality-marketing-areas-of-application_792-352-1.jpg'
import img4 from '../../assets/images/blog/visionar_augmented_reality_app-developer-job_792-352-1.jpg'
import img5 from '../../assets/images/blog/visionar_augemented_reality_augmented-reality-marketing_792-352-1.jpg'
import img6 from '../../assets/images/blog/visionar_augmented_reality_ar-areas-of-application_792-352-1.jpg'

const BlogCardPage2 = () => {
    return (
        <div className="blog-area">
            <div className="container">
                <div className="row">
                    <BlogCard title="Augmented Reality Areas of Application – how different industries and business sectors benefit from it" cover={img6} date="Nov 15, 2019" id={6} />
                    <BlogCard title="Augmented Reality Marketing – potential, possibilities, application areas" cover={img5} date="Oct 9, 2019" id={5} />
                    <BlogCard title="As a freelancer get orders – 5 tips how to get an app developer job" cover={img4} date="Oct 2, 2019" id={4} />
                    <BlogCard title="The mobile augmented reality market – overview, key players and chances" cover={img3} date="Sep 21, 2019" id={3} />
                    <BlogCard title="Develop a mobile app – native, hybrid or web app?" cover={img2} date="Sep 16, 2019" id={2}  />
                    <BlogCard title="Top 10 AR SDK – program the most powerful SDKs to the AR App" cover={img1} date="Sep 10, 2019" id={1} />
                </div>
            </div>
        </div>
    )
}

export default BlogCardPage2