import React from 'react'
import BlogCard from './BlogCard'
import img1 from '../../assets/images/blog/visionar_augmented_reality_ar-in-business__fin_792-352.jpg'
import img2 from '../../assets/images/blog/visionar_augemented_reality_5_creative_marketing-_ideas.jpg'
import img3 from '../../assets/images/blog/visionar_augmented_reality_how_augmented_reality_really_works.jpg'
import img4 from '../../assets/images/blog/visionar_augemented_reality_top5_augmented_reality_trends_in_2020.jpg'
import img5 from '../../assets/images/blog/visionar_augemented_reality_the_3_best_smart_glasses_in_2020.jpg'
import img6 from '../../assets/images/blog/visionar_augemented_reality_AR-contact-lenses.jpg'
import img7 from '../../assets/images/blog/visionar_augmented_reality_metaverse.png'
import img8 from '../../assets/images/blog/visionar_augmented_reality_ar-trends_792-352.jpg'

const BlogCardPage1 = () => {
    return (
        <div className="blog-area">
            <div className="container">
                <div className="row">
                    <BlogCard title="Top 5 AR Trends That Will Transform How We Live in 2022" cover={img8} date="May 1, 2022" id={14} />
                    <BlogCard title="Top 5 ways in which augmented reality will be used in Metaverse" cover={img7} date="Apr 8, 2022" id={13} />
                    <BlogCard title="AR Contact Lenses – Sounds Like SciFi, But It Is Closer Than You Think" cover={img6} date="Mar 10, 2020" id={12} />
                    <BlogCard title="The 3 best smart glasses in 2020" cover={img5} date="Feb 14, 2020" id={11}  />
                    <BlogCard title="Top 5 Augmented Reality Trends in 2020" cover={img4} date="Jan 29, 2020" id={10}  />
                    <BlogCard title="How augmented reality works" cover={img3} date="Jan 10, 2020" id={9} />
                    <BlogCard title="5 creative marketing ideas to impress your clients" cover={img2} date="Jan 3, 2020" id={8}  />
                    <BlogCard title="AR in Business – Its power and future" cover={img1} date="Nov 22, 2019" id={7}  />
                </div>
            </div>
        </div>
    )
}

export default BlogCardPage1
