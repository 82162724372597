import React from 'react'
import { Link } from 'gatsby'

const BlogCard = ({cover, title, date, id}) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                    <Link to={`/blog/${id}`}>
                        <img style={{height: '200px' , objectFit:'cover'}} src={cover} alt="" />
                    </Link>
                </div>

                <div className="post-content">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                        <li>
                            <div className="post-author d-flex align-items-center">
                                <span>visionar editorial office</span>
                            </div>
                        </li>
                        <li>
                            <i className='flaticon-calendar'></i> {date}
                        </li>
                    </ul>
                    <h3>
                        <Link to={`/blog/${id}`}>
                            {title}
                        </Link>
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default BlogCard